<template>
  <meta name="viewport"
    content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no">
  <meta name="apple-mobile-web-app-capable" content="yes">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <v-layout class="rounded rounded-md">
    <HeadView />
    <router-view></router-view>
  </v-layout>
</template>
<script>
import HeadView from '@/components/navabar/HeadView.vue';

export default {
  components: {
    HeadView,
  },
}
</script>