import router from "./router";
import { createApp } from "vue";
import App from "./App.vue";
import '@mdi/font/css/materialdesignicons.css' 
const app = createApp(App);
import VueScrollTo from 'vue-scrollto';
import {  zhHans } from 'vuetify/locale'
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: 'zhHans',
    messages: { zhHans },
  },
}) 

app.use(VueScrollTo); 
app.use(vuetify); 
app.use(router);  
app.mount("#app");