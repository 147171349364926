<template>
  <v-main>
    <div class="main">
      <div ref="messageContainer" class="message-container">
        <div v-for="message in  messages " :key="message.id" class="message">
          <div :class="getMessageClass(message.sender)"
            style="display: flex; align-items: flex-start; justify-content: center;" v-if="message.text">
            <div v-if="message.sender == 'assistant'" style=" padding-right: 10px;">
              <div
                style="width: 50px; height: 50px; border: 1px solid #ddd; border-radius: 10px; display: flex; align-items: center; justify-content: center;">
                <div style="padding-top: 10px;">
                  <img :src="message.avatar" :class="getMessageClass(message.sender)"
                    style="max-width: 100%; max-height: 100%;" />
                  <v-tooltip activator="parent" location="top">{{ formatTime(message.time) }}
                  </v-tooltip>
                </div>
              </div>
            </div>
            <div class="content">
              <div v-html="message.text">
              </div>
            </div>
            <div style=" padding-left: 10px;">

              <img :src="message.avatar" :class="getMessageClass(message.sender)"
                style="margin-left: 0px; width: 50px; height: 50px; border: 1px solid #ddd;   border-radius: 10px;"
                v-if="message.sender == 'user'" />
              <v-tooltip activator="parent" location="top">{{ formatTime(message.time) }}
              </v-tooltip>
            </div>
          </div>
          <div :class="getMessageClass(message.sender)"
            style="display: flex; align-items: flex-start; justify-content: center;" v-if="!message.text">
            <div
              style="width: 50px; height: 50px; border: 1px solid #ddd; border-radius: 10px; display: flex; align-items: center; justify-content: center;">
              <div style="padding-top: 10px;">
                <img :src="message.avatar" :class="getMessageClass(message.sender)"
                  style="max-width: 100%; max-height: 100%;" />
                <v-tooltip activator="parent" location="top">{{ formatTime(message.time) }}
                </v-tooltip>
              </div>
            </div>
            <div style="padding-left: 30px;" v-if="message.sender == 'assistant'">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
            <div>
              <img :src="message.avatar" :class="getMessageClass(message.sender)"
                style="margin-left: 0px; width: 50px; height: 50px" v-if="message.sender == 'user'" />
              <v-tooltip activator="parent" location="top">{{ formatTime(message.time) }}
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-main>

  <v-footer app name="footer">
    <v-textarea :append-icon="sign ? 'mdi-send-check' : 'mdi-send-lock'"
      :append-outer-icon="!sign ? '' : 'mdi-send-check'" class="mx-2" label="请输入" rows="1" max-rows="5" counter
      :rules="rules" v-model="textarea2" auto-grow @click:append="sendMeg" @focus="scrollToInput"></v-textarea>
  </v-footer>

  <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" :multi-line="snackbar.multiLine"
    :vertical="snackbar.vertical" :style="{ bottom: `90%` }">
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>
import UserImage from '@/assets/imgs/user.png';
import OwnerImage from '@/assets/imgs/owner.png';
import axios from 'axios';
export default {

  data() {
    return {
      i: 100,
      code: "",
      rules: [v => v.length <= 3000 || '最大输入3000字'],
      textarea2: '',
      messages: [],
      sign: true,
      snackbar: {
        show: false,
        text: "",
        color: "",
        multiLine: false,
        vertical: false,
        timeout: 1000
      },
    };
  },

  methods: {
    formatTime(timeString) {
      const date = new Date(timeString);
      const formattedTime = date.toLocaleString();
      return formattedTime;
    },
    getMessageClass(sender) {
      return sender === "user" ? "right" : "left";
    },
    sendMeg() {
      if (this.sign && this.textarea2) {
        const item = {
          id: this.i,
          sender: "user",
          avatar: OwnerImage,
          text: this.textarea2,
        }
        this.messages.push(item)
        this.messages.push({
          id: this.i,
          sender: "assistant",
          avatar: UserImage,
          text: '',
        })
        this.sign = false;
        axios.post('/api/chat/message/send', { content: this.textarea2, role: "chanzhong" })
          .then(response => {
            const status = response.data.status;
            const time = response.data.time;
            this.responseData = response.data;
            const text = this.format(this.responseData.content)
            if (!status) {
              this.showSnackbar(text, 'warning')
            }
            const item = {
              id: this.i,
              sender: "assistant",
              avatar: UserImage,
              text: text,
              time: time,
            }
            this.messages = [...this.messages.slice(0, -1), item];
            this.$nextTick(() => {
              this.scrollToBottom();
            });
            this.sign = true;

          })
          .catch(error => {
            this.showSnackbar(error.response.data.msg, "error");
          });
        this.textarea2 = ""
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
      else {
        this.showSnackbar('请输入消息', 'warning')
      }
    },
    format(e) {
      return e.replace(/\\n/g, '<br>').replace(/\\"/g, '"')
    },
    unformat(e) {
      return e.replace(/<br>/g, '\n')
    },
    scrollToBottom() {
      // 获取消息容器引用，并滚动到底部
      const container = this.$refs.messageContainer;
      container.scrollTop = container.scrollHeight;
    },
    showSnackbar(text, color, timeout = 1000) {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
      this.snackbar.timeout = timeout;
    },
    initChatMsg() {
      axios.post('/api/chat/message/data', {}).then(response => {
        const msgDara = response.data
        msgDara.forEach(element => {
          const role = element.role
          const text = this.format(element.content)
          var avatar = OwnerImage
          if (role !== 'user') {
            avatar = UserImage
          }
          const item = {
            id: element.id,
            sender: role,
            avatar: avatar,
            text: text,
            time: element.time
          }
          this.messages.push(item)
        });
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }).catch((error) => {
        this.showSnackbar(error.response.data.msg, "error", 500);
        setTimeout(() => {
          this.$router.push("/login");
        }, 2000);
      });
    },
    scrollToInput() {
      this.$scrollTo('.v-input__control', 0, {
        offset: -250, // 设置一个适当的偏移量
      });
    },
  },
  created() {
    this.initChatMsg()
  }
};

</script>

<style >
.main::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.main {
  height: 500px;
  overflow-y: scroll;
  padding-top: 1%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.message-container::-webkit-scrollbar {
  width: 0;
  display: none;
}

.message-container {
  overflow-y: scroll;
  max-width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.content {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  /* 设置代码块的背景色 */
  /* 设置代码块的内边距 */
  border: 1px solid #ddd;
  /* 设置代码块的边框 */
  border-radius: 10px;
  /* 设置代码块的边框圆角 */
  font-family: monospace;
  /* 设置代码块的字体 */
  color: #333;
  font-size: 1rem;
  line-height: 1.55;
  max-width: 100%;

}

.message {
  display: block;
  white-space: normal;
  padding-bottom: 1%;
}

.left {
  float: left;
  padding-bottom: 10px;
}

.right {
  float: right;
  max-width: 100%;
}
</style>