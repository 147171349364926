import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import dialogView from '../components/model/dialogView.vue'
import UserImage from '@/assets/imgs/user.png';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '南华秘境'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: dialogView,
    meta: {
      title: '南华秘境-登录'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.afterEach((to) => {
  document.title = to.meta.title;
});

router.afterEach((to) => {
  document.title = to.meta.title;
  // 动态设置页面图标
  const iconLink = document.querySelector("link[rel~='icon']") || document.createElement('link');
  iconLink.type = 'image/x-icon';
  iconLink.rel = 'icon';
  iconLink.href = to.meta.icon || UserImage; // 默认使用UserImage
  document.head.appendChild(iconLink);
});
export default router
