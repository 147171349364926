<template>
    <v-app-bar color="#404040">
        <v-toolbar-title @click="goroute('/')">
            南华秘境
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn variant="text" icon="mdi mdi-account-plus" @click="openLoginForm" v-if="!user"></v-btn>
        <!-- <v-list v-if="user" class="custom-background">
            <v-list-item :append-avatar="avatar" :title="user" subtitle="" @click="userdialog = true">
            </v-list-item>
        </v-list> -->
        <v-menu>
            <template v-slot:activator="{ props }">
                <v-list v-if="user" class="custom-background">
                    <v-list-item :append-avatar="avatar" :title="user" subtitle="" icon v-bind="props">
                    </v-list-item>
                </v-list>
            </template>
            <v-card>
                <v-card-text>
                    <div class="mx-auto text-center">
                        <h3>{{ user }}</h3>
                        <p class="text-caption mt-1">
                            {{ useremail }}
                        </p>
                        <v-divider class="my-1"></v-divider>
                        <div class="d-flex flex-column">
                            <v-btn rounded class="mt-4" color="indigo-darken-3" @click="userdialog = true">
                                个人信息
                            </v-btn>
                        </div>
                        <v-divider class="my-1"></v-divider>
                        <div class="d-flex flex-column">
                            <v-btn rounded class="mt-4" color="warning" @click="DelMesdialog = true">
                                清空历史对话
                            </v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-menu>
    </v-app-bar>


    <v-dialog v-model="loginFormVisible" max-width="400">
        <v-card>
            <v-card-title class="text-center">
                <h3>
                    登录
                </h3>
            </v-card-title>
            <v-card-text>
                <v-form ref="loginForm">
                    <v-text-field v-model="username" label="用户名或邮箱"
                        :rules="[rules.required, rules.minLengthRule]"></v-text-field>
                    <v-text-field v-model="password" label="密码" type="password"
                        :rules="[rules.required, rules.min2LengthRule]"></v-text-field>
                    <v-btn block class="mt-2" @click="submitLoginForm('login')">立即登录</v-btn>
                    <v-card-title class="text-center">
                        <v-row align="center" dense>
                            <v-col class="px-2">
                                <h6>
                                    还没有账号?
                                    <a href="#" @click.prevent="registerUser">注册新用户</a>
                                </h6>
                            </v-col>
                        </v-row>
                    </v-card-title>

                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="registerFormVisible" max-width="400">
        <v-card>
            <v-card-title class="text-center">
                <h3>
                    注册新用户
                </h3>
            </v-card-title>
            <v-card-text>
                <v-form ref="registerForm">
                    <v-text-field v-model="username" label="用户名"
                        :rules="[rules.required, rules.minLengthRule, rules.valid, rules.UsernameCheckRule]">
                    </v-text-field>
                    <v-text-field v-model="email" label="邮箱"
                        :rules="[rules.required, rules.email, rules.emailCheckRule]"></v-text-field>
                    <v-btn color="yellow" size="x-small" v-if="findemail">找回邮箱</v-btn>
                    <v-text-field v-model="password" label="密码" type="password"
                        :rules="[rules.required, rules.min2LengthRule]"></v-text-field>
                    <v-text-field v-model="confirmPasswor" label="确认密码" type="password"
                        :rules="[rules.required, rules.matchPassword, rules.min2LengthRule]"></v-text-field>
                    <v-card-title class="text-center">
                        <v-row align="center" dense>
                            <v-col class="px-2">
                                <h6>
                                    注册登录即表示同意
                                    <a href="#" @click.prevent="showPrivacyPolicy('user')">用户协议、隐私政策</a>
                                </h6>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-btn block class="mt-2" @click="submitLoginForm('register')">立即注册</v-btn>
                    <v-card-title class="text-center">
                        <v-row align="center" dense>
                            <v-col class="px-2">
                                <h6>
                                    已有账号?
                                    <a href="#" @click.prevent="loginUser">立即登录</a>
                                </h6>
                            </v-col>
                        </v-row>
                    </v-card-title>

                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :multi-line="snackbar.multiLine"
        :vertical="snackbar.vertical" :style="{ bottom: `90%` }">
        {{ snackbar.text }}
    </v-snackbar>


    <v-dialog v-model="privacyVisible" width="800">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ privacyText }}</span>
            </v-card-title>
            <v-card-text>
                <PravifyView />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green-darken-1" variant="text" @click="privacyVisible = false">
                    关闭
                </v-btn>
                <v-btn color="green-darken-1" variant="text" @click="privacyVisible = false">
                    同意
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="userdialog" persistent width="400px">
        <v-card>
            <v-card-title style="text-align: center;">
                <v-avatar size="x-large">
                    <v-img :src="avatar" :alt="user"></v-img>
                </v-avatar>
            </v-card-title>
            <v-card-text>
                <v-sheet width="90%" class="mx-auto">
                    <v-form fast-fail @submit.prevent>
                        <v-text-field v-model="user" label="昵称" :rules="[rules.required]" readonly></v-text-field>
                        <v-text-field v-model="useremail" label="邮箱" :rules="[rules.required]" readonly></v-text-field>
                        <v-select v-model="sex" :items="sexitems" :rules="[rules.required]" label="性别" required></v-select>
                        <v-text-field v-model="birthday" label="生日" :rules="[rules.required]"
                            @click="userdialog = false; datedialog = true"></v-text-field>
                        <v-text-field v-model="addr" label="地址" :rules="[rules.required]"></v-text-field>
                        <v-btn type="submit" block class="mt-2">正念统计</v-btn>
                        <v-btn type="submit" block class="mt-2">账户安全</v-btn>
                    </v-form>
                </v-sheet>
            </v-card-text>
            <v-card-actions>
                <v-btn color="warning" @click="logout">
                    退出登录
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green-darken-1" variant="text" @click="userdialog = false">
                    关闭
                </v-btn>
                <v-btn color="green-darken-1" variant="text" @click="userUpdate">
                    保存
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="datedialog" persistent fullscreen>
        <v-card>
            <div class="custom-text-center">
                <v-date-picker title='出生年月' v-model="date" locale="zh-cn" @click="formattedDate"></v-date-picker>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded class="mt-4" color="primary" @click="datedialog = false">
                    关闭
                </v-btn>
                <v-btn color="green-darken-1" rounded class="mt-4" @click="datedialog = false; userdialog = true">
                    确认
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="DelMesdialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">确认</span>
            </v-card-title>
            <v-card-text>
                <span style="color: red;">清除后将无法找回，是否清除？</span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="DelMessage">是</v-btn>
                <v-btn color="primary" @click="DelMesdialog = false">否</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';
import PravifyView from '@/components/PravifyView.vue';
import UserImage from '@/assets/imgs/user.png';
// import zhHans from 'vuetify/lib/locale/zh-Hans';
export default {
    components: {
        PravifyView
    },
    data() {
        return {
            date: new Date(),
            snackbar: {
                show: false,
                text: "",
                color: "",
                multiLine: false,
                vertical: false,
            },
            fieldErrors: {
                username: '初始用户名错误消息',
                email: '初始邮箱错误消息',
            },
            loginFormVisible: false,
            registerFormVisible: false,
            privacyVisible: false,
            email: '',
            username: '',
            password: '',
            confirmPasswor: '',
            privacyTitle: "",
            privacyText: "",
            findemail: false,
            rules: {
                required: value => !!value || '该字段不能为空',
                email: value => /.+@.+\..+/.test(value) || '请输入有效的邮箱地址',
                valid: value => /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(value) || '用户名只能包含字母、数字、下划线和汉字',
                matchPassword: value => value === this.password || '确认密码与密码不一致',
                minLengthRule: value => (value && value.length > 1) || '长度必须大于1',
                min2LengthRule: value => (value && value.length >= 6) || '长度必须>=6',
                UsernameCheckRule: async value => {
                    try {
                        const response = await axios.post('/api/check', { name: 'username', value: value });
                        if (response.data.exists) {
                            return '该用户名已存在';
                        }
                    } catch (error) {
                        console.error('Error checking username:', error);
                    }
                    return true;
                },
                emailCheckRule: async value => {
                    try {
                        const response = await axios.post('/api/check', { name: 'email', value: value });
                        if (response.data.exists) {
                            this.findemail = true
                            return '该邮箱已存在';
                        }
                        else {
                            this.findemail = false
                        }
                    } catch (error) {
                        console.error('Error checking email:', error);
                    }
                    return true;
                },
            },
            avatar: UserImage,
            user: "",
            userdialog: false,
            sexitems: [
                '男',
                '女',
            ],
            sex: null,
            useremail: "",
            birthday: "",
            addr: "",
            datedialog: false,
            DelMesdialog: false,
        };
    },
    methods: {
        openLoginForm() {
            this.loginFormVisible = true;
        },
        closeForm(e) {
            if (e === 'login') {
                this.loginFormVisible = false;
            }
            else {
                this.registerFormVisible = false
            }
        },
        submitLoginForm(e) {
            if (e === 'register') {
                this.$refs.registerForm.validate().then(valid => {
                    if (valid.valid) {
                        axios.post('/api/register', { username: this.username, password: this.password, email: this.email })
                            .then(response => {
                                this.responseData = response.data;
                                this.showSnackbar("注册成功，现在登录！", 'success')
                                this.closeForm('');
                                this.loginFormVisible = true;
                            }).catch((error) => {
                                this.showSnackbar(error.response.data.msg, "error");
                            });
                        this.$refs.registerForm.reset()
                    } else {
                        console.log('校验失败，请检查输入');
                    }
                });
            }
            else {
                this.$refs.loginForm.validate().then(valid => {
                    const item = {
                        "password": this.password
                    }
                    if (this.validateEmail(this.username)) {
                        item['email'] = this.username
                    }
                    else {
                        item['username'] = this.username
                    }
                    if (valid.valid) {
                        axios.post('/api/login', item)
                            .then(response => {
                                const status = response.data.status;
                                const msg = response.data.msg;
                                if (status) {
                                    this.showSnackbar(msg, 'success')
                                    this.goroute('/')
                                }
                                this.closeForm('login');
                            }).catch((error) => {
                                this.showSnackbar(error.response.data.msg, "error");
                            });
                        this.$refs.loginForm.reset()
                    } else {
                        console.log('校验失败，请检查输入');
                    }
                });
            }
        },
        showSnackbar(text, color) {
            this.snackbar.text = text;
            this.snackbar.color = color;
            this.snackbar.show = true;
        },
        validateEmail() {
            // 使用正则表达式验证邮箱格式
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(this.username);
            if (!isValidEmail) {
                // 显示错误消息或执行其他操作
                return false
            }
            return true
        },
        showPrivacyPolicy(e) {
            // 在这里执行打开用户协议的操作
            this.privacyVisible = true
            if (e === 'user') {
                this.privacyTitle = "用户服务协议和隐私政策"
                this.privacyText = ``
            }
        },
        registerUser() {
            this.loginFormVisible = false
            this.registerFormVisible = true
        },
        loginUser() {
            this.registerFormVisible = false
            this.loginFormVisible = true
        },
        goroute(e) {
            this.$router.push(e);
        },
        getuser() {
            axios.post('/api/user', {})
                .then(response => {
                    this.user = response.data.username
                    this.useremail = response.data.email
                    this.addr = response.data.addr
                    this.sex = response.data.sex
                    this.birthday = response.data.birthday
                }).catch((error) => {
                    this.showSnackbar(error.response.data.msg, "error");
                });
        },
        logout() {
            axios.post('/api/logout', {})
                .then(response => {
                    const status = response.data.status;
                    const msg = response.data.msg;
                    this.showSnackbar(msg, 'success')

                    if (status) {
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 2000);
                    }
                }).catch((error) => {
                    this.showSnackbar(error.response.data.msg, "error");
                });
        },
        userUpdate() {
            axios.post('/api/user/update', {
                // username: this.user,
                sex: this.sex,
                birthday: this.birthday,
                addr: this.addr,
            })
                .then(response => {
                    const msg = response.data.msg;
                    this.showSnackbar(msg, 'success')
                    this.userdialog = false
                }).catch((error) => {
                    this.showSnackbar(error.response.data.msg, "error");
                });
        },
        formattedDate() {
            var date = new Date(this.date);
            var year = date.getFullYear();
            var month = ("0" + (date.getMonth() + 1)).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);
            this.birthday = year + "-" + month + "-" + day;
            console.log(date)
        },
        DelMessage() {
            this.sendAxios('/api/chat/message/delete', {
            }, true)
            this.DelMesdialog = false
        },
        sendAxios(url, data, goHome) {
            axios.post(url, data)
                .then(response => {
                    const msg = response.data.msg;
                    this.showSnackbar(msg, 'success')
                    if (goHome) {
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 2000);
                    }
                }).catch((error) => {
                    this.showSnackbar(error.response.data.msg, "error");
                });
        }

    },
    created() {
        this.getuser()
    }
};
</script>

<style scoped>
.custom-background {
    background-color: #404040;
    color: rgb(255, 255, 255);
}

.custom-text-center {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>